/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .altai-translatable-input {
  display: flex;
  padding-bottom: 5px;
}
.altai-theme-admin .altai-translatable-input .lang {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  text-transform: uppercase;
  align-items: center;
  background-color: #e4e4e4;
  justify-content: center;
  font-family: Roboto;
  font-size: small;
  font-weight: 100;
}
